<template>
  <v-container fluid>
    <v-row no-gutter>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab v-for="(item, i) in ['Mis ingresos', 'Facturación']" :key="i">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Payout />
          </v-tab-item>
          <v-tab-item>
            <Commisions />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { colors } from '@/constants/colors.js';
import Payout from './Payout.vue';
import Commisions from './Commision.vue';
export default {
  components: { Payout, Commisions },
  data() {
    return {
      tab: 0,
      color: colors,
    };
  },
};
</script>
<style scoped></style>
