<template>
  <v-card class="px-5 py-10" color="white">
    <v-row justify="center" class="mx-0 my-4">
      <span class="title font-weight-bold"
        >Estamos preparando tu reporte, esto puede tardar unos minutos</span
      >
    </v-row>
    <v-row justify="center" class="ma-0">
      <v-img
        src="@/assets/images/shared/loading-report.gif"
        max-width="150"
        contain
      ></v-img>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-progress-linear
          :value="(reportProgress / payoutsLength) * 100"
          color="primary"
          height="25"
          rounded
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-0 mt-8">
      <v-btn
        color="primary"
        class="white--text"
        rounded
        @click="HandleCancel()"
      >
        Cancelar
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ReportDialog",
  props: {
    reportProgress: {
      type: Number,
      default: 0,
    },
    payoutsLength: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    HandleCancel() {
      this.$emit("cancel");
    }
  },
};
</script>

<style>
</style>