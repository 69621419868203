<!--Sección de dispersiones de organizaciones-->
<template>
  <v-container class="pa-10" fluid>
    <Chat></Chat>

    <!--<p class="text-h4 font-weight-bold">Dispersiones</p>-->
    <v-row>
      <v-col cols="12">
        <!--<h1 class="text-center">Dispersiones</h1>-->
        <p class="text-center font-weight-black headline mb-0 px-3">
          Dispersiones
          <v-tooltip top max-width="350px">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" class="ml-2" dark v-on="on"
                >mdi-help-circle</v-icon
              >
            </template>
            <span
              >Este apartado te muestra todos los pagos recibidos en tu cuenta,
              puedes ver el detalle de tu pagos en la columna de "Ver detalle"
              en la tabla siguiente.
            </span>
          </v-tooltip>
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify-md="space-between" justify="center">
      <Pagination
        :searchByName="false"
        :searchByLimit="false"
        :searchByPage="false"
        :pagination="pagination"
        @search="search"
      >
        <template v-slot:buttonDowload>
          <v-btn
            color="amber darken-1"
            rounded
            :loading="downloadButtonLoader"
            @click="downloadReport()"
            :disabled="
              (!user.permissions.reportsDownload ||
                !user.permissions.reportsDownload.update) &&
              user.role == 'ORGANIZATION_USER'
            "
          >
            <v-icon>mdi-file-download</v-icon>
            Descargar
          </v-btn>
        </template>
      </Pagination>
    </v-row>
    <v-row justify="center">
      <modal-template
        width="90%"
        :open="isModalOpen"
        @close="closeDetailModal"
        @click:outside="closeDetailModal"
      >
        <template #content>
          <v-row>
            <v-col cols="12" class="text-center">
              <p class="text-center font-weight-bold headline mb-0">
                Monto de dispersión
                <!--<v-chip small :color="payoutStatusColors[payoutDetail.status]" dark>
                            {{ payoutDetail.status }}
                              </v-chip>-->
              </p>
              <!--<p class="text-h5">Dispersión Total</p>-->
              <p class="display-1 font-weight-medium mb-1">{{ payoutTotal }}</p>
              <v-chip
                small
                :color="payoutStatusColors[payoutDetail.status]"
                dark
              >
                {{ payoutDetail.status }}
              </v-chip>
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-if="payoutDetail.details.length > 0"
                :headers="payoutDetail.cols"
                :items="payoutDetail.details"
                :expanded.sync="payoutDetail.expanded"
                :loading="payoutDetail.loading"
                item-key="id"
                show-expand
                single-expand
                class="elevation-0"
              >
                <template #[`item.status`]="{ item }">
                  <!--<v-chip :color="payoutStatusColors[item.status]" dark>
                              {{ item.status }}
                                </v-chip>-->
                  <p
                    class="font-weight-bold"
                    :class="
                      item.status == 'COMPLETADA'
                        ? 'green--text'
                        : 'orange--text'
                    "
                  >
                    {{ item.status }}
                  </p>
                </template>
                <template #expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="grey lighten-4">
                    <v-data-table
                      :headers="[
                        { text: 'Detalle', value: 'description' },
                        { text: 'Monto', value: 'amount' },
                        { text: 'Tipo de Tarifa', value: 'type' },
                      ]"
                      :items="item.feeDetails"
                      hide-default-footer
                      disable-sort
                      item-key="id"
                      class="grey lighten-4"
                    >
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </template>
      </modal-template>
      <v-col cols="12">
        <v-data-table
          :headers="cols"
          :items="payouts"
          :loading="tableLoading"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50],
          }"
          class="elevation-0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                class="text-center"
                :class="[
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-table-row'
                    : '',
                ]"
              >
                <template v-for="colData in cols">
                  <td
                    v-if="
                      colData.value !== 'actions' &&
                      colData.value !== 'status' &&
                      colData.value != 'arrivalDate'
                    "
                    :key="colData.value"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      {{ item[colData.value] }}{{ colData.extraValue }}
                    </div>
                  </td>
                  <td
                    v-else-if="colData.value == 'status'"
                    :key="colData.value + '_'"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      <p
                        class="font-weight-bold"
                        :class="
                          item[colData.value] == 'PAGADO'
                            ? 'green--text'
                            : 'grey--text'
                        "
                      >
                        {{ item[colData.value] }}
                      </p>
                    </div>
                  </td>
                  <td
                    v-else-if="colData.value == 'arrivalDate'"
                    :key="colData.value + '__'"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      <span>{{ formattedDate(item[colData.value]) }}</span>
                    </div>
                  </td>
                  <td
                    v-else
                    :key="colData.value + ''"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="black"
                            @click="
                              seePayoutDetail(item.id, item.stripeAccountType)
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary"
                              >mdi-file-document-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Ver detalle de dispersión</span>
                      </v-tooltip>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="reportDialog" width="800" persistent>
      <ReportDialog
        :reportProgress="reportProgress"
        :payoutsLength="payouts.length"
        @cancel="cancelReport = true"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import Chat from '@/components/shared/Chat.vue';
import moment from 'moment';
import ModalTemplate from '@/components/shared/ModalTemplate';
import Endpoints from '@/share/Endpoints';
import { formatPrice, getDefaultRangePeriod } from '@/utils';
import { mapMutations, mapState } from 'vuex';
import * as XLSX from 'xlsx/xlsx.mjs';
import Pagination from '../../../components/dashboard/pagination/Pagination';
import ReportDialog from '../../../components/shared/Reports/ReportDialog';

export default {
  components: {
    ModalTemplate,
    Pagination,
    ReportDialog,
    Chat,
  },
  data: () => ({
    reportDialog: false,
    reportProgress: 0,
    cancelReport: false,
    downloadButtonLoader: false,
    dateMenu1: false,
    dateMenu2: false,
    initDate: null,
    endDate: null,
    payouts: [],
    tableLoading: false,
    isModalOpen: false,
    paymentStatuses: {
      paid: 'PAGADO',
      default: 'PENDIENTE',
    },
    cols: [
      {
        text: 'Código',
        align: 'center',
        value: 'id',
      },
      {
        text: 'Importe',
        align: 'center',
        value: 'amount',
      },
      {
        text: 'Estatus',
        align: 'center',
        value: 'status',
      },
      {
        text: 'Llegada Prevista',
        align: 'center',
        value: 'arrivalDate',
      },
      {
        text: 'Ver detalle',
        align: 'center',
        value: 'actions',
      },
    ],
    payoutDetail: {
      payoutTotal: 0,
      status: '',
      loading: false,
      expanded: [],
      details: [],
      cols: [
        /*{
          text: 'Código de Transacción',
          value: 'id'
        },*/
        {
          text: 'Nombre',
          value: 'name',
        },
        {
          text: 'Correo',
          value: 'email',
        },
        {
          text: 'Dispersión',
          value: 'netAmount',
          align: 'center',
        },
        {
          text: 'Comisión',
          value: 'fee',
          align: 'center',
        },
        {
          text: 'Total',
          value: 'amount',
          align: 'center',
        },
        {
          text: 'Estado',
          value: 'status',
          align: 'center',
        },
        {
          text: 'Ver Detalle',
          value: 'data-table-expand',
        },
      ],
    },
    payoutStatusColors: {
      COMPLETADA: 'green',
      PENDIENTE: 'orange',
    },
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 0,
      gte: null,
      lte: null,
      name: '',
    },
  }),
  async created() {
    try {
      this.loading();

      const range = getDefaultRangePeriod();
      const format = 'YYYY-MM-DD';
      this.pagination.gte = moment(range.startDate).format(format);
      this.pagination.lte = moment(range.endDate).format(format);
      this.getPayouts();

      this.loaded();
    } catch (error) {
      console.log(error);
      this.loaded();
    }
  },
  methods: {
    ...mapMutations('loader', ['loading', 'loaded']),
    handleDateChange() {
      console.log('handleDateChange');
      if (this.endDate < this.initDate) {
        this.endDate = this.initDate;
      } else {
      }
    },
    search(Pagination) {
      this.pagination = Pagination;
      this.getPayouts();
    },
    async getPayouts() {
      this.tableLoading = true;
      const response = await this.axios.get(Endpoints.usersPayouts, {
        params: {
          gte: this.pagination.gte,
          lte: this.pagination.lte,
        },
      });
      if (response.status === 200) {
        const { payouts } = response.data;
        this.payouts = payouts.map((payout) => ({
          ...payout,
          amount: formatPrice(payout.amount),
          status:
            this.paymentStatuses[payout.status] || this.paymentStatuses.default,
        }));
        this.tableLoading = false;
      }
    },
    async seePayoutDetail(id, stripeAccountType) {
      this.loading();
      this.payoutDetail.loading = true;
      const response = await this.axios.get(
        Endpoints.usersPayoutsDetail
          .replace(/:payoutId/, id)
          .replace(':stripeAccountType', stripeAccountType)
      );
      if (response.status === 200) {
        const { payout } = response.data;
        const getStatus = (status) =>
          status === 'available' ? 'COMPLETADA' : 'PENDIENTE';
        this.payoutDetail = {
          ...this.payoutDetail,
          payoutTotal: payout.amount,
          status: getStatus(payout.status),
          details: payout.charges.map((charge) => ({
            ...charge,
            amount: formatPrice(charge.amount),
            fee: formatPrice(charge.fee),
            netAmount: formatPrice(charge.netAmount),
            status: getStatus(charge.status),
            feeDetails: charge.feeDetails.map((details) => ({
              ...details,
              amount: formatPrice(details.amount),
            })),
          })),
        };
        this.payoutDetail.loading = false;
      }
      this.isModalOpen = true;
      this.loaded();
    },
    async downloadReport() {
      this.reportDialog = true;
      this.reportProgress = 0;
      let reportArray = [];
      console.log(this.payouts);
      for (let i = 0; i < this.payouts.length; i++) {
        if (this.cancelReport == true) {
          this.cancelReport = false;
          this.reportDialog = false;
          return;
        }

        this.reportProgress = i;
        const response = await this.axios({
          url: `/users/individual-payout-report?payout=${this.payouts[i].id}&date=${this.payouts[i].arrivalDate}&type=${this.payouts[i].stripeAccountType}`,
          baseURL: process.env.VUE_APP_REPORT_ENDPOINT,
          method: 'GET',
        });
        reportArray = reportArray.concat(response.data.payoutReport);
      }

      if (this.cancelReport == true) {
        this.cancelReport = false;
        this.reportDialog = false;
        return;
      }
      const ws = XLSX.utils.json_to_sheet(reportArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Payouts');
      XLSX.writeFile(wb, 'Payouts.xlsx');

      this.reportDialog = false;
    },
    async downloadPayoutsReport() {
      //format payouts arrival date and headers
      this.downloadButtonLoader = true;
      console.log(this.pagination.gte);
      console.log(this.pagination.lte);

      const response = await this.axios.get(Endpoints.usersPayoutsReport, {
        params: {
          month: '01',
          year: '2023',
          gte: this.pagination.gte,
          lte: this.pagination.lte,
        },
      });

      const ws = XLSX.utils.json_to_sheet(response.data.payoutReport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Payouts');
      XLSX.writeFile(wb, 'Payouts.xlsx');

      this.downloadButtonLoader = false;
    },
    closeDetailModal() {
      this.isModalOpen = false;
      this.payoutDetail = {
        ...this.payoutDetail,
        details: [],
        payoutTotal: 0,
        status: '',
        expanded: [],
      };
    },
    formattedDate(date) {
      let newDate = new Date(date);
      newDate = newDate.toLocaleDateString('es-MX');
      let newTime = this.timeConvert(date.substring(11, 16));
      let [year, month, day] = date.substring(0, 10).split('-');
      return `${day}-${month}-${year}`;
    },
    formattedHour(date) {
      let newTime = this.timeConvert(date.substring(11, 16));
      return newTime;
    },
    timeConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    },
  },
  computed: {
    ...mapState('user', ['user']),
    payoutTotal() {
      return formatPrice(this.payoutDetail.payoutTotal);
    },
  },
};
</script>

<style>
.v-progress-linear--rounded {
  border-radius: 16px;
}
</style>
