<template>
  <v-col cols="12" class="py-5">
    <v-row justify="space-around" align="center">
      <v-col cols="11" md="5" class="" v-if="searchByName">
        <div class="searchbar d-flex flex-row">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn
                v-on="on"
                fab
                small
                elevation="0"
                class="rounded-pill rounded-r-0"
                color="#feb300"
                @click="search()"
              >
                <v-icon size="20">mdi-magnify</v-icon></v-btn
              >
            </template>
            Click para buscar
          </v-tooltip>
          <input
            type="text"
            style="background: #f0f0f0; width: 100%"
            placeholder="Buscar miembro"
            filled
            rounded
            class="busqueda rounded-pill rounded-l-0 pl-2"
            v-model="Pagination.name"
            v-on:keypress.enter="search()"
          />
        </div>
      </v-col>
      <v-col cols="11" md="5" lg="3" class="pt-10" v-if="searchByLimit">
        <v-select
          v-model="Pagination.limit"
          label="transacciones por página"
          filled
          rounded
          @input="search()"
          :items="listSize"
          item-text="text"
          item-value="value"
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="center" justify-md="space-around">
      <v-col cols="11" md="5" lg="3" class="" v-if="searchByDate">
        <v-menu
          style="background: #f0f0f0;"
          v-model="dateFilter"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="Pagination.gte"
              label="Fecha inicial"
              persistent-hint
              readonly
              rounded
              dense
              v-on="on"
              append-icon="mdi-calendar"
              required
              v-bind="attrs"
            />
          </template>
          <v-date-picker
            :max="Pagination.lte"
            v-model="Pagination.gte"
            @change="handleDateSelect"
            locale="es"
          />
        </v-menu>
      </v-col>

      <v-col cols="11" md="5" lg="3" class="" v-if="searchByDate">
        <v-menu
          style="background: #f0f0f0;"
          v-model="dateFilter2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="Pagination.lte"
              label="Fecha Final"
              persistent-hint
              readonly
              rounded
              dense
              v-on="on"
              append-icon="mdi-calendar"
              required
              v-bind="attrs"
            />
          </template>
          <v-date-picker
            :min="Pagination.gte"
            v-model="Pagination.lte"
            @change="handleDateSelect"
            locale="es"
          />
        </v-menu>
      </v-col>

      <slot name="buttonDowload"></slot>
    </v-row>

    <div class="text-center pt-3" v-if="searchByPage">
      <v-pagination
        v-model="Pagination.page"
        @input="search()"
        :length="Pagination.totalPages"
        :total-visible="10"
      />
    </div>
  </v-col>
</template>
<script>
export default {
  props: {
    pagination: { type: Object, required: true },
    searchByDate: {
      type: Boolean,
      default: true,
    },
    searchByLimit: {
      type: Boolean,
      default: true,
    },
    searchByPage: {
      type: Boolean,
      default: true,
    },
    searchByName: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateFilter: false,
    dateFilter2: false,
    Pagination: {
      page: 1,
      limit: 10,
      totalPages: 0,
      gte: null,
      lte: null,
      name: "",
    },
  }),
  computed: {
    listSize() {
      let list = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "250", value: 250 },
        { text: "500", value: 500 },
        { text: "1000", value: 1000 },
        {
          text: "Todos",
          value: this.Pagination.totalPages * this.Pagination.limit + 1,
        },
      ];
      return list;
    },
  },
  watch: {
    pagination() {
      this.Pagination = this.pagination;
    },
  },
  created() {
    this.Pagination = this.pagination;
  },
  methods: {
    search() {
      this.$emit("search", this.Pagination);
    },
    handleDateSelect() {
      this.search();
    },
  },
};
</script>