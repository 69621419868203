<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!--<h1 class="text-center">Dispersiones</h1>-->
        <p class="text-center font-weight-black headline mb-0 px-3">
          Facturas de comisiones
          <v-tooltip top max-width="350px">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" class="ml-2" dark v-on="on"
                >mdi-help-circle</v-icon
              >
            </template>
            <span
              >En este apartado podrás encontrar el histórico de facturas
              generadas por el monto total de las comisiones cobradas durante el
              mes.
            </span>
          </v-tooltip>
        </p>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="historicData"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50],
          }"
          class="elevation-0"
        >
        <template v-slot:item.pdf="{ item }">
        <div class="d-flex justify-space-around">
          <v-btn fab x-small elevation="0" text dense @click="downloadInvoice(item.invoice)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { downloadFile } from "@/utils";

export default {
  data() {
    return {
      headers: [
        { text: 'ID', value: 'invoice' },
        { text: 'Importe', value: 'total' },
        { text: 'Fecha Inicial', value: 'gte' },
        { text: 'Fecha Final', value: 'lte' },
        { text: 'PDF', value: 'pdf', align: 'center' },
      ],
      historicArray: [],
    };
  },
  methods: {
    ...mapActions('invoice', ['fetchExpressInvoices']),

    async getInvoiceList() {
      let response = await this.fetchExpressInvoices();
      console.log(response);
      if (response.status) {
        this.historicArray = response.data;
      }
    },

    async downloadInvoice(id) {
      console.log(id);
      let response = await this.axios.get(
        `/invoices/download-express-invoice/${id}`,
        {
          responseType: "arraybuffer"
        }
      )
      if (response.status > 199 && response.status < 300) {
        const { data } = response;
        downloadFile(data, "application/zip", `${id}.zip`);
      } else {
        this.show({
          text: "¡Ha ocurrido un problema al descargar la factura!",
          color: "error",
        });
      }
    }
  },
  computed: {
    historicData: {
      get() {
        return this.historicArray;
      },
    },
  },
  async created() {
    await this.getInvoiceList();
  },
};
</script>
<style scoped></style>
