var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-10",attrs:{"fluid":""}},[_c('Chat'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center font-weight-black headline mb-0 px-3"},[_vm._v(" Dispersiones "),_c('v-tooltip',{attrs:{"top":"","max-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v("Este apartado te muestra todos los pagos recibidos en tu cuenta, puedes ver el detalle de tu pagos en la columna de \"Ver detalle\" en la tabla siguiente. ")])])],1)])],1),_c('v-row',{attrs:{"align":"center","justify-md":"space-between","justify":"center"}},[_c('Pagination',{attrs:{"searchByName":false,"searchByLimit":false,"searchByPage":false,"pagination":_vm.pagination},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"buttonDowload",fn:function(){return [_c('v-btn',{attrs:{"color":"amber darken-1","rounded":"","loading":_vm.downloadButtonLoader,"disabled":(!_vm.user.permissions.reportsDownload ||
              !_vm.user.permissions.reportsDownload.update) &&
            _vm.user.role == 'ORGANIZATION_USER'},on:{"click":function($event){return _vm.downloadReport()}}},[_c('v-icon',[_vm._v("mdi-file-download")]),_vm._v(" Descargar ")],1)]},proxy:true}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('modal-template',{attrs:{"width":"90%","open":_vm.isModalOpen},on:{"close":_vm.closeDetailModal,"click:outside":_vm.closeDetailModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center font-weight-bold headline mb-0"},[_vm._v(" Monto de dispersión ")]),_c('p',{staticClass:"display-1 font-weight-medium mb-1"},[_vm._v(_vm._s(_vm.payoutTotal))]),_c('v-chip',{attrs:{"small":"","color":_vm.payoutStatusColors[_vm.payoutDetail.status],"dark":""}},[_vm._v(" "+_vm._s(_vm.payoutDetail.status)+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.payoutDetail.details.length > 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.payoutDetail.cols,"items":_vm.payoutDetail.details,"expanded":_vm.payoutDetail.expanded,"loading":_vm.payoutDetail.loading,"item-key":"id","show-expand":"","single-expand":""},on:{"update:expanded":function($event){return _vm.$set(_vm.payoutDetail, "expanded", $event)}},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('p',{staticClass:"font-weight-bold",class:item.status == 'COMPLETADA'
                      ? 'green--text'
                      : 'orange--text'},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"grey lighten-4",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":[
                      { text: 'Detalle', value: 'description' },
                      { text: 'Monto', value: 'amount' },
                      { text: 'Tipo de Tarifa', value: 'type' },
                    ],"items":item.feeDetails,"hide-default-footer":"","disable-sort":"","item-key":"id"}})],1)]}}],null,true)}):_vm._e()],1)],1)]},proxy:true}])}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.cols,"items":_vm.payouts,"loading":_vm.tableLoading,"footer-props":{
          itemsPerPageOptions: [10, 20, 50],
        }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"text-center",class:[
                _vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-table-row'
                  : '',
              ]},[_vm._l((_vm.cols),function(colData){return [(
                    colData.value !== 'actions' &&
                    colData.value !== 'status' &&
                    colData.value != 'arrivalDate'
                  )?_c('td',{key:colData.value,class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row'
                      : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(colData.text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''},[_vm._v(" "+_vm._s(item[colData.value])+_vm._s(colData.extraValue)+" ")])]):(colData.value == 'status')?_c('td',{key:colData.value + '_',class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row'
                      : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(colData.text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''},[_c('p',{staticClass:"font-weight-bold",class:item[colData.value] == 'PAGADO'
                          ? 'green--text'
                          : 'grey--text'},[_vm._v(" "+_vm._s(item[colData.value])+" ")])])]):(colData.value == 'arrivalDate')?_c('td',{key:colData.value + '__',class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row'
                      : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(colData.text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''},[_c('span',[_vm._v(_vm._s(_vm.formattedDate(item[colData.value])))])])]):_c('td',{key:colData.value + '',class:_vm.$vuetify.breakpoint.xsOnly
                      ? 'v-data-table__mobile-row'
                      : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(colData.text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.seePayoutDetail(item.id, item.stripeAccountType)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle de dispersión")])])],1)])]})],2)}),0)]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"800","persistent":""},model:{value:(_vm.reportDialog),callback:function ($$v) {_vm.reportDialog=$$v},expression:"reportDialog"}},[_c('ReportDialog',{attrs:{"reportProgress":_vm.reportProgress,"payoutsLength":_vm.payouts.length},on:{"cancel":function($event){_vm.cancelReport = true}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }